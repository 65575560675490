export const HOMEDATA = [
  {
    title: 'Asesores Estratégicos',
    textList: [
      {
        text: 'Garantizamos información relevante y óptima para los Usuarios de la información financiera; comercial, instituciones fiscalizadoras.'
      }
    ]
  },
  {
    title: 'Asesoría Financiera',
    textList: [
      {
        text: 'Buscar las ventas optimas para que la sociedad sea rentable y pueda solventar arriendos, sueldos, proveedores y rentabilidad.'
      }
    ]
  },
  {
    title: 'Compromiso Empresarial',
    textList: [
      {
        text: 'Nuestra asesoría va orientada por cada rubro, buscamos maximizar el potencial de nuestros clientes.'
      }
    ]
  }
];

export const LISTDATA = [
  {
    text: 'Inversora GCP, nos definimos como una empresa dedicada a la Asesoría Empresarial, otorgándole a nuestros Clientes Proyecciones de sus Negocios, además de contribuir en el cumplimiento de Normas Tributarias, Laborales, Comerciales y/o Legales Nuestro Valor Agregado está en la orientación y servicio al cliente, complementados con la experiencia y conocimientos sólidos'
  },
  // {
  //   text: 'Médicos'
  // },
  // {
  //   text: 'Odontólogos'
  // }
];

export const LISTDATA2 = [
  {
    text: 'Contabilidad Mensual'
  },
  {
    text: 'Declaracion de impuestos DAI'
  },
  {
    text: 'Gestión de Imposiciones'
  },
  {
    text: 'Administración de Pagos'
  },
  {
    text: 'Informe Financiero'
  },
  {
    text: 'Informe de Impuestos'
  }
];

export const CARDTEXTS = [
  {
    title: 'Nosotros',
    text: `Somos una sociedad de profesionales expertos en materia Financiera, Tributaria y Planificación Estratégica. Nuestra manera de asesorar a un Empresario, Profesional o Emprendedor es participar en la toma de decisiones financieras de sus proyectos, ámbitos personales, poder identificar y minimizar riesgos propios de su mercado o negocio.`
  },
  {
    title: 'Misión - Visión',
    text: `Comenzar con emprendedores, microempresarios y ayudarles en su desarrollo empresarial y entregarle herramientas competitivas en su formación.`
  }
];

export const TEAMLIST = [
  {
    text: 'Carlos Cereceda',
    description: 'Contador Auditor'
  },
  {
    text: 'Dulce Rivero',
    description: 'Asistente Comercial'
  },
  {
    text: 'Andrea Gonzalez',
    description: 'Jefa de Recursos Humanos'
  },
  {
    text: 'Carlos Rozas',
    description: 'Contador Auditor'
  },
  {
    text: 'David Valderrama',
    description: 'Analista de Sistemas TI'
  },
  {
    text: 'Fabian Saavedra',
    description: 'Diseñador Gráfico'
  }
];

export const NAVBARITEMS = [
  {
    title: 'Servicios',
    dropdown: true
  },
  {
    title: 'Comienza tu empresa',
    dropdown: false
  },
  {
    title: 'Auditoría',
    dropdown: false
  }
];