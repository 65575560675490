import React from 'react'
import './List.css'

const List = ({title, data, textColor, bulletColor, textAlign}) => {
    return (
        <div>
            <h3>{title ? title : null}</h3>
            <ul className={'list'}>
                {data.map((item, index) => {
                    return (
                    <li className={`${bulletColor ? 'list-item-color' : 'list-item'} list-${textColor}-color ${textAlign}`} key={index}>{item.text} {item.description && item.description}</li>
                    )
                })}
            </ul>
        </div>
    )
}

export default List
