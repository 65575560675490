import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer id={'footer'}>
            <div className={'top-bar'}>
                <p className={'top-bar-txt'}>Tienes un mensaje para nosotros? Hablemos!</p>
            </div>

            <div className={'bottom-footer'}>
                <div className='footer-cards'>
                    <div className={'footer-card'}>
                        <div className={'footer-card-element'}>
                            <h3>Envianos un mensaje...</h3>
                            <a href="tel: +56-9-3387-0559" rel="noreferrer" className='contact-link'>
                                <h3 className='footer-contact-info'>+56-9-3387-0559</h3>
                            </a>
                        </div>

                        <div className={'footer-card-element'}>
                            <h3>Email</h3>
                            <a href = "mailto: contacto@inversoragcp.com" rel="noreferrer" className='contact-link' target='_blank'>
                                <h4 className='footer-contact-info'>Contacto@inversoragcp.com</h4>
                            </a>
                        </div>

                        <div className={'footer-card-element'}>
                            <h3>Siguenos!</h3>
                            <div className={'social-media'}>
                                <a href = "mailto: contacto@inversoragcp.com" rel="noreferrer" className='contact-link' target='_blank'>
                                    <i className="fab fa-twitter social-icon"></i>
                                </a>
                                <a href = "mailto: contacto@inversoragcp.com" rel="noreferrer" className='contact-link' target='_blank'>
                                    <i className="fab fa-facebook social-icon"></i>
                                </a>
                                <a href = "mailto: contacto@inversoragcp.com" rel="noreferrer" className='contact-link' target='_blank'>
                                    <i className="fab fa-instagram social-icon"></i>
                                </a>
                            </div>

                        </div>



                    </div>
                    <hr/>
                    <div className={'footer-card-map'}>
                        <div className={'footer-card-maps-header'}>
                            <i className="fas fa-map-marker-alt find-us-icon"></i>
                            <h3>Encuentranos</h3>
                        </div>
                        <div className={'footer-card-maps'}>
                            <div className={'map-footer'}>
                                <a target='_blank' rel="noreferrer" className='contact-link' href='https://www.google.com/maps/place/Av.+Providencia+2133,+Providencia,+Región+Metropolitana,+Chile/@-33.4225469,-70.610542,17z/data=!4m2!3m1!1s0x9662cf66122dc547:0xe7ec534f3a506b44'>
                                    <h4 className='footer-contact-info'>Providencia: Av. Providencia 2133, Oficina 306</h4>
                                </a>
                                <iframe title={'map'} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3330.026751731207!2d-70.610542!3d-33.4225469!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf66122dc547%3A0xe7ec534f3a506b44!2sAv.%20Providencia%202133%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses-419!2sar!4v1620100380553!5m2!1ses-419!2sar" width="250" height="200" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                            
                            <div className={'map-footer'}>
                                <a target='_blank' rel="noreferrer" className='contact-link' href='https://www.google.com/maps/place/Badajoz+100,+oficina+523,+Las+Condes,+Regi%C3%B3n+Metropolitana,+Chile/@-33.4080911,-70.574926,17z/data=!3m1!4b1!4m5!3m4!1s0x9662cf8dd699d0f5:0x69a7b705d55dfde3!8m2!3d-33.4080911!4d-70.5727373'>
                                    <h4 className='footer-contact-info'>Las Condes: Badajoz 100, oficina 523</h4>
                                </a>
                                <iframe title={'map'} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.5811110742625!2d-70.5749259848017!3d-33.408091080786186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf8dd699d0f5%3A0x69a7b705d55dfde3!2sBadajoz%20100%2C%20oficina%20523%2C%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses-419!2sar!4v1606709439862!5m2!1ses-419!2sar" width="250" height="200" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    {/* <div className={'footer-card'}>
                        <div className={'footer-menu-header'}>
                            <h3>Servicios</h3>
                        </div>
                        <div className={'footer-menu-header'}>
                            <h3>Comienza tu empresa</h3>
                        </div>
                        <div className={'footer-menu-header'}>
                            <h3>Auditoría</h3>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    )
}

export default Footer;