import React from 'react';
import '../../App.css';
import Button from '../Button/Button';
import Card from '../Card/Card';
import './Home.css';
import { HOMEDATA, LISTDATA, LISTDATA2, CARDTEXTS, TEAMLIST } from './../../constants'
import BannerEquipo from './../../images/banner-equipo.jpg'
import Icon from '../Icon/Icon';
import List from '../List/List';

const Home = () => {
  return (
    <div className={'home-background'}>
      <div className='home-banner'>
        <div className='header'>
          <h1 className=''>Asesoria empresarial especializada</h1>
          <div className='text-div'>
            <p className='text'>Financiera y Estrategica.</p>
            <p className='text'>Tributaria y Contable.</p>
            <p className='text'>Recursos Humanos.</p>
            <p className='text'>Grupos Empresariales</p>
          </div>

          <Button title={'Contáctanos'} to={'footer'} link={false}/>
        </div>
      </div>
      <div className={'home-section'}>
        <div className={'home-cards'}>
          <Card title={HOMEDATA[0].title} dataListWithIcon={HOMEDATA[0].textList} bckGrnd={true}/>
          <Card title={HOMEDATA[1].title} dataListWithIcon={HOMEDATA[1].textList} bckGrnd={true}/>
          <Card title={HOMEDATA[2].title} dataListWithIcon={HOMEDATA[2].textList} bckGrnd={true}/>
        </div>
        <hr></hr>

        <div className={'home-lists'}>
          <div className={'lists'}>
            <List title='¿Quienes somos?' data={LISTDATA} bulletColor={true}/>
            <hr className='horizontal-row'></hr>
            <List title='Servicios' data={LISTDATA2} bulletColor={true}/>
          </div>

          <div className={'div-img-doctores'}>
            <img src={BannerEquipo} alt="doctores" className={'img-doctores'}/>
          </div>
        </div>


        <div className='icons-container'>
          <Icon icon={'fas fa-chart-line'} text={'Asesores Estratégicos'}/>
          <Icon icon={'far fa-lightbulb'} text={'Expertos Financieros y Tributarios'}/>
          <Icon icon={'fas fa-user-tie'} text={'Compromiso Empresarial'}/>
        </div>

        <div className='home-cards-2'>
          <Card title={CARDTEXTS[0].title} dataParagraph={CARDTEXTS[0].text} cardTxtColor={'grey'}/>
          <hr/>
          <Card title={CARDTEXTS[1].title} dataParagraph={CARDTEXTS[1].text} cardTxtColor={'grey'}/>
          <hr/>
          <Card title={'Team'} dataList={TEAMLIST} cardTxtColor={'gray'} textAlign={'center'}/>
        </div>
          <div className={'bottom-h1-container'}>
            <h1 className={'bottom-h1'}>Desde hoy trabajamos en equipo</h1>
          </div>
      </div>

    </div>
  );
}

export default Home;