import React from 'react';
import List from '../List/List';
import './Card.css';

const Card = ({title, dataList, dataParagraph, dataListWithIcon, bckGrnd, cardTxtColor, textAlign}) => {
    return (
        <div className={'card'}>
            <div className={`card-header ${bckGrnd && 'header-background'}`}>
                <h3>{title}</h3>
            </div>

            {dataList && 
                <div className={'card-body'} >
                    <List data={dataList} textColor={cardTxtColor} textAlign={textAlign}/>
                </div>
            }

            {dataListWithIcon && dataListWithIcon.map((item, index) => {
                return (
                    <div className={'card-body'} key={index}>
                        <i className="fas fa-check-circle"></i> <p>{item.text}</p>
                    </div>
                )
            })}

            {dataParagraph &&
                <div className={'card-body'}>
                    <p className={'card-paragraph'} style={{color:cardTxtColor}}>{dataParagraph}</p>
                </div>
            }
      </div>
    )
}

export default Card
