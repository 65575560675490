import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../Dropdown/Dropdown';

const NavBarItem = ({title, closeMobileMenu, dropdown, scrolled, onMouseEnter, onMouseLeave, path}) => {
    
    let x = ['navbar'];
  
    if(scrolled){
        x.push('scrolled');
    }

    return (<li className='nav-item' 
    onMouseEnter={() => onMouseEnter ? onMouseEnter() : null}
    onMouseLeave={() => onMouseLeave ? onMouseLeave() : null}>
        <Link to={path ? path : ''} className='nav-links' onClick={closeMobileMenu}>
            {title}
        </Link>
        {
            dropdown && 
                 <Dropdown scrolled={scrolled}/>
        }
    </li>
    );
};

export default NavBarItem;
