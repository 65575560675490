import React, { useEffect, useState } from 'react';
// import Button from './../Button/Button';
import { Link } from 'react-router-dom';
import NavBarItem from '../NavBarItem/NavBarItem';
import { NAVBARITEMS } from '../../constants';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [scrolled,setScrolled]= useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 20 ) setScrolled(true);
    else setScrolled(false);
  };

  useEffect(() => {
    window.addEventListener('scroll',handleScroll);
  },[scrolled]);

  let x = ['navbar'];
  
  if(scrolled){
    x.push('scrolled');
  }

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
        setDropdown(false);
    } else {
        setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
        setDropdown(false);
    } else {
        setDropdown(false);
    }
  };

  return (
  <header className={x.join(" ")}>
    <nav className='navbar'>
      <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
        <img src='logo_light.png' className='logo-img' alt='logo'/>
      </Link>
      
      <div className='menu-icon' onClick={handleClick}>
        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
      
      <div className='menu-icon' onClick={handleClick}>
        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
      
      <ul className={click ? `${scrolled && click ? 'nav-scrolled' : ''} nav-menu active` : 'nav-menu'}>
        <NavBarItem
          scrolled={scrolled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          setDropdown={setDropdown} 
          dropdown={dropdown}
          closeMobileMenu={closeMobileMenu} 
          title={NAVBARITEMS[0].title}/>

        <NavBarItem
          path={'/start-up'}
          scrolled={scrolled} 
          setDropdown={setDropdown} 
          closeMobileMenu={closeMobileMenu} 
          title={NAVBARITEMS[1].title}/>

        <NavBarItem
          path={'/auditoria'}
          scrolled={scrolled} 
          setDropdown={setDropdown} 
          closeMobileMenu={closeMobileMenu} 
          title={NAVBARITEMS[2].title}/>
        
        {/* <li>
          <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
            Registrate
          </Link>
        </li> */}
        </ul>
        {/* <Button title={'Registrate'} /> */}
        <div className={dropdown ? 'dropdown-background' : null}></div>
      </nav>
    </header>
  );
};

export default Navbar;