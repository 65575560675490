import React from 'react';
import MyNavbar from './components/Navbar/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GestionPYME from './components/pages/GestionPYME';
import GestionMedEmp from './components/pages/GestionMedEmp';
import StartUp from './components/pages/StartUp';
import Audit from './components/pages/Audit';
import ContactBar from './components/ContactBar/ContactBar';
import Footer from './components/Footer/Footer';
import GestionGranEmp from './components/pages/GestionGranEmp';

function App() {
  return (
    <Router>
      <ContactBar/>
      <MyNavbar/>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/gestion-pyme' component={GestionPYME} />
        <Route path='/gestion-med-emp' component={GestionMedEmp} />
        <Route path='/gestion-gran-emp' component={GestionGranEmp} />
        <Route path='/start-up' component={StartUp} />
        <Route path='/auditoria' component={Audit} />
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
