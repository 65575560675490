export const DropdownMenuItems = [
  {
    header: 'Servicios',
    cNameHeader: 'dropdown-header',
    subMenu: [
      {
        title: 'Gestión PYME',
        path: '/gestion-pyme',
        cName: 'dropdown-link'
      },
      {
        title: 'Gestión mediana empresa',
        path: '/gestion-med-emp',
        cName: 'dropdown-link'
      },
      {
        title: 'Gestión grande empresa',
        path: '/gestion-gran-emp',
        cName: 'dropdown-link'
      }
    ]
  }
];
