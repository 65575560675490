import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const Button = ({title, to, link}) => {
  return (link ?
    <Link to={to} className='btn-link'>
      <button className='btn'>{title}</button>
    </Link> : 
    <a href={`#${to}`} className={'btn btn-link'}>{title}</a>
  );
}

export default Button;