import React from 'react'
import './ContactBar.css'

const ContactBar = () => {
    return (
        <>
            <div className='contact-bar'>
                <div className={'contact-div'}>
                    <i className="fas fa-map-marker-alt"></i>
                    <a target='_blank' rel="noreferrer" className='contact-link' href='https://www.google.com/maps/place/Av.+Providencia+2133,+Providencia,+Región+Metropolitana,+Chile/@-33.4225469,-70.610542,17z/data=!4m2!3m1!1s0x9662cf66122dc547:0xe7ec534f3a506b44'>
                        <h4 className='contact-info'>Providencia: Av. Providencia 2133, Oficina 306</h4>
                    </a>
                </div>
                
                <div className={'contact-div'}>
                    <i className="fas fa-map-marker-alt"></i>
                    <a target='_blank' rel="noreferrer" className='contact-link' href='https://www.google.com/maps/place/Badajoz+100,+oficina+523,+Las+Condes,+Regi%C3%B3n+Metropolitana,+Chile/@-33.4080911,-70.574926,17z/data=!3m1!4b1!4m5!3m4!1s0x9662cf8dd699d0f5:0x69a7b705d55dfde3!8m2!3d-33.4080911!4d-70.5727373'>
                        <h4 className='contact-info'>Las Condes: Badajoz 100, Oficina 523</h4>
                    </a>
                </div>

                <div className={'contact-div'}>
                    <i className="fas fa-phone"></i>
                    <a href="tel: +56-9-3387-0559" rel="noreferrer" className='contact-link'>
                        <h4 className='contact-info'>+56-9-3387-0559</h4>
                    </a>
                </div>

                <div className={'contact-div'}>
                    <i className="fas fa-envelope"></i>
                    <a href = "mailto: contacto@inversoragcp.com" rel="noreferrer" className='contact-link' target='_blank'>
                        <h4 className='contact-info'>Contacto@inversoragcp.com</h4>
                    </a>
                </div>
            </div>  
        </>
    )
}

export default ContactBar
