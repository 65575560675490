import React, { useState } from 'react';
import { DropdownMenuItems } from './../MenuItems';
import './Dropdown.css';
import { Link } from 'react-router-dom';

function Dropdown({scrolled}) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <div className={click ? 'dropdown-menu clicked' : scrolled ? 'dropdown-menu-scrolled' : 'dropdown-menu'}>
        <h3 className='dropdown-list-header'>{DropdownMenuItems[0].header}</h3>
        <ul
          onClick={handleClick}
          className={'dropdown-menu-list'}
        >
          {DropdownMenuItems[0].subMenu.map((item, index) => {
            return (
              <li key={index}>
                <Link
                  className={item.cName}
                  to={item.path}
                  onClick={() => setClick(false)}
                >
                  {`• ${item.title}`}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Dropdown;