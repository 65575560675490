import React from 'react'
import './Icon.css'

const Icon = ({icon, text}) => {
    return (
        <div className="icon-container">
            <div className={'icon-div'}>
              <i className={`${icon} icon`}></i>
            </div>
            <p className='icon-text'>{text}</p>
        </div>
    )
}

export default Icon
